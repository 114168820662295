module Main exposing (main, subscriptions, view)

import Browser

import Types exposing (Msg(..), AuthState(..), Donations)
import State exposing (Model, init, pageTitle, showLogin)
import Routes exposing (Route(..))
import Views.Login exposing (loginView, discordSigninButton)
import Views.Home exposing (homeView)
import Views.Search exposing (homeSearchView)
import Views.BanList exposing (banListView)
import Views.Donate exposing (donateView)
import Views.BanPolicy exposing (banPolicyView)
import Views.ServerAccess exposing (serverAccessView)
import Views.Player exposing (playerView)
import Views.Server exposing (serverView)
import Views.Map exposing (mapView)
import Views.Anticheat exposing (anticheatView)

import Html exposing (Html, a, text, div, img, h5, p)
import Html.Attributes exposing (src, href, target, id)
import Url.Builder
import RemoteData exposing (RemoteData(..))

import Material
import Material.Options as Options exposing (cs, css, styled)
import Material.TopAppBar as TopAppBar
import Material.Snackbar as Snackbar
import Material.LayoutGrid as LayoutGrid
import Material.Typography as Typography
import Material.Button as Button
import Json.Decode

import Ports exposing (discordAuthStateSet, authDetailsUpdated, recaptchaCode)

import Views.Components.Ban exposing (banModal)
import Views.Components.ModNote exposing (modNoteModal)
import Views.Components.Donate exposing (donationsProgressBar, donationsText)
import Views.Components.Misc exposing (discordButton, protectServerButton)

import Misc exposing (hasACL, httpErrorGridCell)

import Resources exposing (resourcePath)

import Time
import Url.Builder

main : Program Json.Decode.Value Model Msg
main =
  Browser.application
    {
      init = init,
      view = view,
      update = State.update,
      subscriptions = subscriptions,
      onUrlChange = UrlChanged,
      onUrlRequest = LinkClicked
    }


-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
  Sub.batch
    [ Time.every 1000 Tick
    , Time.every 30000 RunRouteCmd
    , Material.subscriptions Mdc model
    , discordAuthStateSet DiscordStateSet
    , authDetailsUpdated AuthDetailsUpdated
    , recaptchaCode RecaptchaCode
    ]


-- VIEW

view : Model -> Browser.Document Msg
view model =
  {
    title = pageTitle model,
    body = [
      Snackbar.view Mdc "ba-snackbar" model.mdc [ Snackbar.dismissible ] [],
      div [ id "recaptcha" ] [],
      if not model.cookieConsentAccepted then
        cookieConsent model
      else
        text "",
      styled div 
        [
          cs "main-content"
        ]
        (
          if showLogin model then
            [ loginView model ]
          else
            case model.route of
              Just (DiscordOAuthRoute _ _) ->
                [ loginView model ]
              Just (DonateRoute) ->
                [ donateView model ]
              _ ->
                [
                  banModal model,
                  modNoteModal model,
                  topBar model True,
                  styled div [ TopAppBar.denseFixedAdjust ] [],
                  if model.showDonations && model.route /= (Just DonateRoute) then
                    case model.donationsResponse of
                      Success donations ->
                        case model.userACLsResponse of
                          Success acls ->
                            if hasACL acls "read_data_full" then
                              text ""
                            else
                              donationsBar model donations
                          _ ->
                            donationsBar model donations
                      _ ->
                        text ""
                    else
                      text "",
                  case model.route of
                    Just (IndexRoute _) ->
                      if model.playerSearchResponse == NotAsked && model.serverSearchResponse == NotAsked then
                        homeView model
                      else
                        homeSearchView model
                    _ ->
                      styled div
                        [ cs "container" ]
                        [
                          case model.route of
                            Just (IndexRoute _) ->
                              text ""
                            Just (DiscordOAuthRoute _ _) ->
                              text ""
                            Just (DonateRoute) ->
                              text ""
                            Just (BanPolicyRoute) ->
                              banPolicyView model
                            Just ServerAccessRoute ->
                              serverAccessView model
                            Just (BanListRoute _) ->
                              banListView model
                            Just (PlayerRoute _) ->
                              playerView model
                            Just (PlayerBanShorthandRoute _ _) ->
                              playerRedirectView model
                            Just (PlayerByPIDRoute _ _) ->
                              playerRedirectView model
                            Just (PlayerByEAGUIDRoute _) ->
                              playerRedirectView model
                            Just (PlayerByPBGUIDRoute _) ->
                              playerRedirectView model
                            Just (PlayerServerHistoryRoute _ _) ->
                              playerView model
                            Just (PlayerIPHistoryRoute _ _) ->
                              playerView model
                            Just (PlayerLinkedPlayersRoute _) ->
                              playerView model
                            Just (ServerRoute _) ->
                              serverView model
                            Just (MapRoute _) ->
                              mapView model
                            Just (AnticheatRoute _) ->
                              anticheatView model
                            Nothing ->
                              notFoundView
                        ],
                  footer model
                ]
        )
    ]
  }

policyUrl : String -> String
policyUrl policyName =
  Url.Builder.crossOrigin "https://cdn.battlefield.agency" ["policies", policyName ++ ".html"] []


cookieConsent : Model -> Html Msg
cookieConsent model =
  styled div
    [ cs "cookie-consent"
    , cs "container"
    ]
    [ LayoutGrid.view []
      [ LayoutGrid.cell [LayoutGrid.span12]
        [ styled h5 [ Typography.headline5 ] [ text "Cookie consent" ]
        , p []
            [ text "We use cookies to improve your experience, gather performance metrics and to protect our site. To find our more, read our "
            , a
                [ href (policyUrl "privacy")
                , target "_blank"
                ]
                [ text "privacy policy" ]
            , text " and "
            , a
                [ href (policyUrl "cookies")
                , target "_blank"
                ]
                [ text "cookie policy" ]
            , text "."
            ]
        , Button.view Mdc "ba-accept-cookies" model.mdc
          [ Button.raised
          , Button.onClick AcceptCookieConsent ]
          [ text "Okay" ]
        ]
      ]
    ]

playerRedirectView : Model -> Html Msg
playerRedirectView model =
  case model.playerResponse of
    Failure err ->
      httpErrorGridCell err
    _ ->
      text ""

notFoundView : Html Msg
notFoundView = 
  LayoutGrid.view []
    [
      LayoutGrid.cell [ LayoutGrid.span12 ]
        [
          styled div [ Typography.overline ] [ text "Not found" ],
          styled div [ Typography.headline2 ] [ text "404" ]
        ]
    ]

topBarLogo : Model -> Html Msg
topBarLogo model =
  case resourcePath model ["logo-w"] "svg" of
    Just imagePath ->
      styled img
        [
          Options.onClick (NavigateTo (Url.Builder.absolute [] [])),
          Options.attribute <| src imagePath,
          css "height" "22px",
          css "padding" "13px"
        ] []
    Nothing ->
      text ""


donationsBar : Model -> Donations -> Html Msg
donationsBar model donations =
  styled div
    [ cs "container"
    , css "background-color" "transparent"
    , css "border-bottom" "1px solid var(--mdc-theme-text-hint-on-background)"
    ]
    [ LayoutGrid.view
      []
      [ LayoutGrid.cell [ LayoutGrid.span12 ]
        [ donationsProgressBar donations ]
      , LayoutGrid.cell [ LayoutGrid.span12 ]
        [ donateButton model True
        , Button.view Mdc "ba-hide-donations" model.mdc
          [ Button.onClick HideDonations ]
          [ text "Hide" ]
        , donationsText
          donations
          [ css "margin-left" "10px"
          , css "vertical-align" "middle"
          ]
        ]
      ]
    ]

topBar : Model -> Bool -> Html Msg
topBar model loggedIn =
  TopAppBar.view Mdc "ba-navigation" model.mdc
    [ cs "container"
    , TopAppBar.dense
    ]
    [ TopAppBar.section [ TopAppBar.alignStart ]
      [ topBarLogo model
      , case model.route of
          Just (IndexRoute _) ->
            if model.playerSearchResponse == NotAsked && model.serverSearchResponse == NotAsked then
              TopAppBar.actionItem Mdc "ba-stats" model.mdc
                [ Options.onClick (SmoothScroll "stats") ]
                "analytics"
            else
              text ""
          _ ->
            text ""
      , case model.route of
        Just (BanListRoute _) ->
          text ""
        _ ->
          TopAppBar.actionItem Mdc "ba-ban-list" model.mdc
            [ Options.onClick
              ( case model.route of
                  Just (IndexRoute _) ->
                    SmoothScroll "recent-bans"
                  _ ->
                    NavigateTo (Url.Builder.absolute ["banlist"] [])
              )
            ]
            "fact_check"
      ]
    , if loggedIn then
        TopAppBar.section [ TopAppBar.alignEnd ]
          ( case model.authState of
            Authenticated _ ->  
              [ TopAppBar.actionItem Mdc "ba-my-servers" model.mdc [ Options.onClick (NavigateTo (Url.Builder.absolute ["servers"] [])) ] "storage"
              , TopAppBar.actionItem Mdc "ba-logout" model.mdc [ Options.onClick SubmitLogout ] "exit_to_app"
              ]
            NotAuthenticated ->
              [ TopAppBar.actionItem Mdc "ba-login" model.mdc [ Options.onClick ShowLogin ] "login" ]
          )
      else
        text ""
    ]


banPolicyButton : Model -> Html Msg
banPolicyButton model =
  Button.view Mdc "ba-ban-policy-link" model.mdc
    [
      Button.link (
        Url.Builder.absolute [ "ban-policy" ] []
      ),
      Button.icon "policy"
    ]
    [ text "Ban policy" ]


donateButton : Model -> Bool -> Html Msg
donateButton model raised =
  Button.view Mdc "ba-donate-link" model.mdc
    [
      Button.link (Url.Builder.absolute ["donate"] []),
      Button.raised |> Options.when raised,
      Button.icon "attach_money"
    ]
    [ text "Donate" ]


footer : Model -> Html Msg
footer model =
  LayoutGrid.view
    [
      cs "container"
    ]
    [
      case model.authState of
        Authenticated _ ->
          text ""
        NotAuthenticated ->
          if model.showLoginWarning then
            LayoutGrid.cell
                [
                  LayoutGrid.span12,
                  css "border-top" "1px solid gray",
                  css "padding-top" "7px"
                ]
                [
                  styled div 
                    [
                      Typography.body1,
                      css "padding" "8px 0"
                    ]
                    [
                      text "You are seeing a very limited set of data. Log in with Discord to see GUIDs, Punkbuster ban status and playing histories, search for your server, check its population over time and more!"
                    ],
                  discordSigninButton model False,
                  Button.view Mdc "ba-dismiss-login-warning" model.mdc
                    [ Button.onClick DismissLoginWarning ]
                    [ text "Dismiss" ]
                ]
          else
            text "",
      if model.route /= Just ServerAccessRoute && model.showServerAccessWarning then
        case model.serverAccessResponse of
          Success serverAccessItems ->
            if List.length serverAccessItems == 0 then
              LayoutGrid.cell
                [
                  LayoutGrid.span12,
                  css "border-top" "1px solid gray",
                  css "padding-top" "7px"
                ]
                [
                  styled div 
                    [
                      Typography.body1,
                      css "padding" "8px 0"
                    ]
                    [
                      text "You do not have a server verified. Visit \"My servers\" (button on the top right) now to start protecting your server from thousands of banned cheaters!"
                    ],
                  protectServerButton model True,
                  Button.view Mdc "ba-dismiss-serveraccess-warning" model.mdc
                    [ Button.onClick DismissServerAccessWarning ]
                    [ text "Dismiss" ]
                ]
            else
              text ""
          _ ->
            text ""
        else
          text "",
      LayoutGrid.cell 
        [
          LayoutGrid.span12,
          css "border-top" "1px solid gray",
          css "padding-top" "7px"
        ]
        [
          LayoutGrid.inner []
            [
              LayoutGrid.cell
                [
                  LayoutGrid.span7Desktop,
                  LayoutGrid.span4Tablet,
                  LayoutGrid.span4Phone
                ]
                [
                  donateButton model False,
                  discordButton model Nothing False,
                  banPolicyButton model
                ],
              LayoutGrid.cell
                [
                  LayoutGrid.span5Desktop,
                  LayoutGrid.span4Tablet,
                  LayoutGrid.span4Phone,
                  Typography.overline,
                  css "padding-right" "8px",
                  css "text-align" "right"
                ]
                [
                  styled a
                    [
                      Options.attribute <| href (policyUrl "privacy"),
                      Options.attribute <| target "_blank",
                      css "margin-right" "10px",
                      cs "unstyled"
                    ]
                    [ text "Privacy policy" ],
                  styled a
                    [
                      Options.attribute <| href (policyUrl "cookies"),
                      Options.attribute <| target "_blank",
                      css "margin-right" "10px",
                      cs "unstyled"
                    ]
                    [ text "Cookie policy" ],
                  text ("© 2017-" ++ String.fromInt (Time.toYear model.zone model.time) ++ "  Battlefield Agency")
                ]
            ]
        ]
    ]