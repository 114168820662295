// polyfill for custom elements. Optional, see https://caniuse.com/#feat=custom-elementsv1
import "@webcomponents/custom-elements";

import { registerCustomElement, registerPorts } from "elm-mapbox";

// This brings in mapbox required CSS
import "mapbox-gl/dist/mapbox-gl.css";

// Import resources
import resourcesObj from "./res/**/*.*";

// Your Elm application
import { Elm } from "./src/Main.elm";

// Google analytics tracking ID
const gaId = "G-CHD6S9Q7WS";

// Authenticated content token cookie
const setAuthenticatedContentToken = jwt => {
  document.cookie = `authenticated-content-token=${jwt}; Path=/; Domain=.battlefield.agency; Secure;`;
}

const clearAuthenticatedContentToken = () => {
  document.cookie = `authenticated-content-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=.battlefield.agency; Secure;`;
}


// Convert resources structure to list
let resources = []

const toResourceList = (obj, path = []) => {
  let keys = Object.keys(obj);
  // check if it is a resource object
  if (keys.length > 0 && typeof obj[keys[0]] != "object") {
    resources.push({
      "filetype": keys[0],
      "path": path,
      // dist path is in the first sub object key
      "dist": obj[keys[0]],
    });
  // Otherwise, run self recursively
  } else {
    for (let key of keys) {
      // new path is passed path + current key
      toResourceList(obj[key], [...path, key]);
    }
  }
}

toResourceList(resourcesObj);

// A Mapbox API token. Register at https://mapbox.com to get one of these. It's free.
const token =
    "pk.eyJ1IjoiaGVjdG9ycm9iIiwiYSI6ImNrMGNqM3E1ejEyaHUzb21xdHI2bTg3d2UifQ.FG67YwkNiJsqOOUjI_UArw";

// This will add elm-mapbox custom element into the page's registry.
// This **must** happen before your application attempts to render a map.
registerCustomElement({
    token
});

const cookieConsentKey = "cookieConsent";
const authDetailsKey = "authDetails";
const discordAuthStateKey = "discordAuthState";

let cookieConsentAccepted = localStorage.getItem(cookieConsentKey)
cookieConsentAccepted = cookieConsentAccepted ? JSON.parse(cookieConsentAccepted) : false;


const getAuthDetails = () => {
  let authDetails = localStorage.getItem(authDetailsKey);
  return authDetails ? JSON.parse(authDetails) : null;
};

let authDetails = getAuthDetails();
if (authDetails) {
  setAuthenticatedContentToken(authDetails.token);
}

var discordAuthState = localStorage.getItem(discordAuthStateKey);
if (discordAuthState) localStorage.removeItem(discordAuthStateKey);

let blockUpdateAuthDetails = false;


const flags = {cookieConsentAccepted, authDetails, discordAuthState, resources};

let gtagInitialized = false;

function gtag() {window.dataLayer.push(arguments)}

const updateGtag = () => {
  if (!cookieConsentAccepted || gtagInitialized) return;
  // Append Google Analytics script to head
  const gaScriptTag = document.createElement("script");
  gaScriptTag.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id="+gaId);
  gaScriptTag.setAttribute("async", true);
  document.head.appendChild(gaScriptTag);
  // Create gtag function and initialize
  window.dataLayer = window.dataLayer || [];
  gtag('js', new Date());
  gtag('config', gaId);
  gtagInitialized = true;
}


const recaptchaSiteKey = "6LfTGsEZAAAAACZWWH2DRrL-QplkrHr5UCV4rwqJ";


window.onloadCallback = () => {
  updateGtag();

  var app = Elm.Main.init({"node": document.getElementById("elm"), "flags": flags});

  app.ports.cookieConsentAccepted.subscribe(accepted=> {
    cookieConsentAccepted = accepted;
    localStorage.setItem(cookieConsentKey, JSON.stringify(cookieConsentAccepted));
    updateGtag();
  });

  app.ports.updateAuthDetails.subscribe(authDetails => {
    blockUpdateAuthDetails = true;
    if (authDetails) {
      localStorage.setItem(authDetailsKey, JSON.stringify(authDetails));
      setAuthenticatedContentToken(authDetails.token);
    } else {
      localStorage.removeItem(authDetailsKey);
      clearAuthenticatedContentToken();
    }
    blockUpdateAuthDetails = false;
  });

  app.ports.updateDiscordAuthState.subscribe(authState => {
    localStorage.setItem(discordAuthStateKey, authState);
    app.ports.discordAuthStateSet.send(authState);
  });

  setInterval(() => {
    const authDetailsNew = getAuthDetails();
    if (JSON.stringify(authDetailsNew) != JSON.stringify(authDetails) && !blockUpdateAuthDetails) {
      authDetails = authDetailsNew;
      app.ports.authDetailsUpdated.send(authDetails);
    }
  }, 2000);


  app.ports.gaEvent.subscribe((action) => {
    if (gtagInitialized) gtag('event', action);
  });

  app.ports.gaPageView.subscribe(([title, url]) => {
    if (gtagInitialized) gtag('config', gaId, {'page_title': title, 'page_location': url})
  });

  // This is TERRIBLE
  // We should switch back to reCAPTCHA v3 ASAP - once Elm browser.application provides a way to not
  // override the body or reCAPTCHA v3 adds explicit rendering
  let recaptchaRendered = false;
  let currentRecaptchaAction = null;
  let recaptchaActionQueue = [];

  function processRecaptchaActionQueue() {
    if (recaptchaActionQueue.length == 0 || currentRecaptchaAction != null) return;
    currentRecaptchaAction = recaptchaActionQueue.shift();
    console.log("Executing reCAPTCHA", currentRecaptchaAction);
    window.requestAnimationFrame(() => {
      if(!recaptchaRendered) {
        console.log("Rendering reCAPTCHA");
        grecaptcha.render("recaptcha", {
          sitekey: recaptchaSiteKey,
          size: "invisible",
          callback: (code) => {
            console.log("reCAPTCHA code", currentRecaptchaAction, code);
            app.ports.recaptchaCode.send({action: currentRecaptchaAction, code});
            currentRecaptchaAction = null;
            grecaptcha.reset();
            processRecaptchaActionQueue();
          }
        });
        recaptchaRendered = true;
      }
      grecaptcha.execute();
    });
  }

  app.ports.recaptchaExecute.subscribe((action) => {
    recaptchaActionQueue.push(action);
    processRecaptchaActionQueue();
  });
};

// Register ports. You only need to do this if you use the port integration.
// I usually keep this commented out until I need it.
// registerPorts(app);

// Load reCAPTCHA, window.onloadCallback above will be called by it
const recaptchaScriptTag = document.createElement("script");
recaptchaScriptTag.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
document.head.appendChild(recaptchaScriptTag);