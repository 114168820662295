const _temp0 = require("../hero-dark.png");

const _temp1 = require("../hero-light.png");

const _temp2 = require("../logo-dark.svg");

const _temp3 = require("../logo-square.png");

const _temp4 = require("../logo-text-dark.svg");

const _temp5 = require("../logo-text-w.svg");

const _temp6 = require("../logo-w.svg");

const _temp7 = require("../favicon/apple-touch-icon.png");

const _temp8 = require("../favicon/favicon-16x16.png");

const _temp9 = require("../favicon/favicon-32x32.png");

const _temp10 = require("../favicon/favicon.ico");


const _temp11 = require("../flags/ad.png");

const _temp12 = require("../flags/ae.png");

const _temp13 = require("../flags/af.png");

const _temp14 = require("../flags/ag.png");

const _temp15 = require("../flags/ai.png");

const _temp16 = require("../flags/al.png");

const _temp17 = require("../flags/am.png");

const _temp18 = require("../flags/ao.png");

const _temp19 = require("../flags/aq.png");

const _temp20 = require("../flags/ar.png");

const _temp21 = require("../flags/as.png");

const _temp22 = require("../flags/at.png");

const _temp23 = require("../flags/au.png");

const _temp24 = require("../flags/aw.png");

const _temp25 = require("../flags/ax.png");

const _temp26 = require("../flags/az.png");

const _temp27 = require("../flags/ba.png");

const _temp28 = require("../flags/bb.png");

const _temp29 = require("../flags/bd.png");

const _temp30 = require("../flags/be.png");

const _temp31 = require("../flags/bf.png");

const _temp32 = require("../flags/bg.png");

const _temp33 = require("../flags/bh.png");

const _temp34 = require("../flags/bi.png");

const _temp35 = require("../flags/bj.png");

const _temp36 = require("../flags/bl.png");

const _temp37 = require("../flags/bm.png");

const _temp38 = require("../flags/bn.png");

const _temp39 = require("../flags/bo.png");

const _temp40 = require("../flags/bq.png");

const _temp41 = require("../flags/br.png");

const _temp42 = require("../flags/bs.png");

const _temp43 = require("../flags/bt.png");

const _temp44 = require("../flags/bv.png");

const _temp45 = require("../flags/bw.png");

const _temp46 = require("../flags/by.png");

const _temp47 = require("../flags/bz.png");

const _temp48 = require("../flags/ca.png");

const _temp49 = require("../flags/cc.png");

const _temp50 = require("../flags/cd.png");

const _temp51 = require("../flags/cf.png");

const _temp52 = require("../flags/cg.png");

const _temp53 = require("../flags/ch.png");

const _temp54 = require("../flags/ci.png");

const _temp55 = require("../flags/ck.png");

const _temp56 = require("../flags/cl.png");

const _temp57 = require("../flags/cm.png");

const _temp58 = require("../flags/cn.png");

const _temp59 = require("../flags/co.png");

const _temp60 = require("../flags/cr.png");

const _temp61 = require("../flags/cu.png");

const _temp62 = require("../flags/cv.png");

const _temp63 = require("../flags/cw.png");

const _temp64 = require("../flags/cx.png");

const _temp65 = require("../flags/cy.png");

const _temp66 = require("../flags/cz.png");

const _temp67 = require("../flags/de.png");

const _temp68 = require("../flags/dj.png");

const _temp69 = require("../flags/dk.png");

const _temp70 = require("../flags/dm.png");

const _temp71 = require("../flags/do.png");

const _temp72 = require("../flags/dz.png");

const _temp73 = require("../flags/ec.png");

const _temp74 = require("../flags/ee.png");

const _temp75 = require("../flags/eg.png");

const _temp76 = require("../flags/eh.png");

const _temp77 = require("../flags/er.png");

const _temp78 = require("../flags/es.png");

const _temp79 = require("../flags/et.png");

const _temp80 = require("../flags/fi.png");

const _temp81 = require("../flags/fj.png");

const _temp82 = require("../flags/fk.png");

const _temp83 = require("../flags/fm.png");

const _temp84 = require("../flags/fo.png");

const _temp85 = require("../flags/fr.png");

const _temp86 = require("../flags/ga.png");

const _temp87 = require("../flags/gb-eng.png");

const _temp88 = require("../flags/gb-nir.png");

const _temp89 = require("../flags/gb-sct.png");

const _temp90 = require("../flags/gb-wls.png");

const _temp91 = require("../flags/gb.png");

const _temp92 = require("../flags/gd.png");

const _temp93 = require("../flags/ge.png");

const _temp94 = require("../flags/gf.png");

const _temp95 = require("../flags/gg.png");

const _temp96 = require("../flags/gh.png");

const _temp97 = require("../flags/gi.png");

const _temp98 = require("../flags/gl.png");

const _temp99 = require("../flags/gm.png");

const _temp100 = require("../flags/gn.png");

const _temp101 = require("../flags/gp.png");

const _temp102 = require("../flags/gq.png");

const _temp103 = require("../flags/gr.png");

const _temp104 = require("../flags/gs.png");

const _temp105 = require("../flags/gt.png");

const _temp106 = require("../flags/gu.png");

const _temp107 = require("../flags/gw.png");

const _temp108 = require("../flags/gy.png");

const _temp109 = require("../flags/hk.png");

const _temp110 = require("../flags/hm.png");

const _temp111 = require("../flags/hn.png");

const _temp112 = require("../flags/hr.png");

const _temp113 = require("../flags/ht.png");

const _temp114 = require("../flags/hu.png");

const _temp115 = require("../flags/id.png");

const _temp116 = require("../flags/ie.png");

const _temp117 = require("../flags/il.png");

const _temp118 = require("../flags/im.png");

const _temp119 = require("../flags/in.png");

const _temp120 = require("../flags/io.png");

const _temp121 = require("../flags/iq.png");

const _temp122 = require("../flags/ir.png");

const _temp123 = require("../flags/is.png");

const _temp124 = require("../flags/it.png");

const _temp125 = require("../flags/je.png");

const _temp126 = require("../flags/jm.png");

const _temp127 = require("../flags/jo.png");

const _temp128 = require("../flags/jp.png");

const _temp129 = require("../flags/ke.png");

const _temp130 = require("../flags/kg.png");

const _temp131 = require("../flags/kh.png");

const _temp132 = require("../flags/ki.png");

const _temp133 = require("../flags/km.png");

const _temp134 = require("../flags/kn.png");

const _temp135 = require("../flags/kp.png");

const _temp136 = require("../flags/kr.png");

const _temp137 = require("../flags/kw.png");

const _temp138 = require("../flags/ky.png");

const _temp139 = require("../flags/kz.png");

const _temp140 = require("../flags/la.png");

const _temp141 = require("../flags/lb.png");

const _temp142 = require("../flags/lc.png");

const _temp143 = require("../flags/li.png");

const _temp144 = require("../flags/lk.png");

const _temp145 = require("../flags/lr.png");

const _temp146 = require("../flags/ls.png");

const _temp147 = require("../flags/lt.png");

const _temp148 = require("../flags/lu.png");

const _temp149 = require("../flags/lv.png");

const _temp150 = require("../flags/ly.png");

const _temp151 = require("../flags/ma.png");

const _temp152 = require("../flags/mc.png");

const _temp153 = require("../flags/md.png");

const _temp154 = require("../flags/me.png");

const _temp155 = require("../flags/mf.png");

const _temp156 = require("../flags/mg.png");

const _temp157 = require("../flags/mh.png");

const _temp158 = require("../flags/mk.png");

const _temp159 = require("../flags/ml.png");

const _temp160 = require("../flags/mm.png");

const _temp161 = require("../flags/mn.png");

const _temp162 = require("../flags/mo.png");

const _temp163 = require("../flags/mp.png");

const _temp164 = require("../flags/mq.png");

const _temp165 = require("../flags/mr.png");

const _temp166 = require("../flags/ms.png");

const _temp167 = require("../flags/mt.png");

const _temp168 = require("../flags/mu.png");

const _temp169 = require("../flags/mv.png");

const _temp170 = require("../flags/mw.png");

const _temp171 = require("../flags/mx.png");

const _temp172 = require("../flags/my.png");

const _temp173 = require("../flags/mz.png");

const _temp174 = require("../flags/na.png");

const _temp175 = require("../flags/nc.png");

const _temp176 = require("../flags/ne.png");

const _temp177 = require("../flags/nf.png");

const _temp178 = require("../flags/ng.png");

const _temp179 = require("../flags/ni.png");

const _temp180 = require("../flags/nl.png");

const _temp181 = require("../flags/no.png");

const _temp182 = require("../flags/np.png");

const _temp183 = require("../flags/nr.png");

const _temp184 = require("../flags/nu.png");

const _temp185 = require("../flags/nz.png");

const _temp186 = require("../flags/om.png");

const _temp187 = require("../flags/pa.png");

const _temp188 = require("../flags/pe.png");

const _temp189 = require("../flags/pf.png");

const _temp190 = require("../flags/pg.png");

const _temp191 = require("../flags/ph.png");

const _temp192 = require("../flags/pk.png");

const _temp193 = require("../flags/pl.png");

const _temp194 = require("../flags/pm.png");

const _temp195 = require("../flags/pn.png");

const _temp196 = require("../flags/pr.png");

const _temp197 = require("../flags/ps.png");

const _temp198 = require("../flags/pt.png");

const _temp199 = require("../flags/pw.png");

const _temp200 = require("../flags/py.png");

const _temp201 = require("../flags/qa.png");

const _temp202 = require("../flags/re.png");

const _temp203 = require("../flags/ro.png");

const _temp204 = require("../flags/rs.png");

const _temp205 = require("../flags/ru.png");

const _temp206 = require("../flags/rw.png");

const _temp207 = require("../flags/sa.png");

const _temp208 = require("../flags/sb.png");

const _temp209 = require("../flags/sc.png");

const _temp210 = require("../flags/sd.png");

const _temp211 = require("../flags/se.png");

const _temp212 = require("../flags/sg.png");

const _temp213 = require("../flags/sh.png");

const _temp214 = require("../flags/si.png");

const _temp215 = require("../flags/sj.png");

const _temp216 = require("../flags/sk.png");

const _temp217 = require("../flags/sl.png");

const _temp218 = require("../flags/sm.png");

const _temp219 = require("../flags/sn.png");

const _temp220 = require("../flags/so.png");

const _temp221 = require("../flags/sr.png");

const _temp222 = require("../flags/ss.png");

const _temp223 = require("../flags/st.png");

const _temp224 = require("../flags/sv.png");

const _temp225 = require("../flags/sx.png");

const _temp226 = require("../flags/sy.png");

const _temp227 = require("../flags/sz.png");

const _temp228 = require("../flags/tc.png");

const _temp229 = require("../flags/td.png");

const _temp230 = require("../flags/tf.png");

const _temp231 = require("../flags/tg.png");

const _temp232 = require("../flags/th.png");

const _temp233 = require("../flags/tj.png");

const _temp234 = require("../flags/tk.png");

const _temp235 = require("../flags/tl.png");

const _temp236 = require("../flags/tm.png");

const _temp237 = require("../flags/tn.png");

const _temp238 = require("../flags/to.png");

const _temp239 = require("../flags/tr.png");

const _temp240 = require("../flags/tt.png");

const _temp241 = require("../flags/tv.png");

const _temp242 = require("../flags/tw.png");

const _temp243 = require("../flags/tz.png");

const _temp244 = require("../flags/ua.png");

const _temp245 = require("../flags/ug.png");

const _temp246 = require("../flags/um.png");

const _temp247 = require("../flags/us.png");

const _temp248 = require("../flags/uy.png");

const _temp249 = require("../flags/uz.png");

const _temp250 = require("../flags/va.png");

const _temp251 = require("../flags/vc.png");

const _temp252 = require("../flags/ve.png");

const _temp253 = require("../flags/vg.png");

const _temp254 = require("../flags/vi.png");

const _temp255 = require("../flags/vn.png");

const _temp256 = require("../flags/vu.png");

const _temp257 = require("../flags/wf.png");

const _temp258 = require("../flags/ws.png");

const _temp259 = require("../flags/xk.png");

const _temp260 = require("../flags/ye.png");

const _temp261 = require("../flags/yt.png");

const _temp262 = require("../flags/za.png");

const _temp263 = require("../flags/zm.png");

const _temp264 = require("../flags/zw.png");


module.exports = {
  "hero-dark":   {
    "png": _temp0
  },
  "hero-light":   {
    "png": _temp1
  },
  "logo-dark":   {
    "svg": _temp2
  },
  "logo-square":   {
    "png": _temp3
  },
  "logo-text-dark":   {
    "svg": _temp4
  },
  "logo-text-w":   {
    "svg": _temp5
  },
  "logo-w":   {
    "svg": _temp6
  },
  "favicon":   {
    "apple-touch-icon":     {
      "png": _temp7
    },
    "favicon-16x16":     {
      "png": _temp8
    },
    "favicon-32x32":     {
      "png": _temp9
    },
    "favicon":     {
      "ico": _temp10
    }
  },
  "flags":   {
    "ad":     {
      "png": _temp11
    },
    "ae":     {
      "png": _temp12
    },
    "af":     {
      "png": _temp13
    },
    "ag":     {
      "png": _temp14
    },
    "ai":     {
      "png": _temp15
    },
    "al":     {
      "png": _temp16
    },
    "am":     {
      "png": _temp17
    },
    "ao":     {
      "png": _temp18
    },
    "aq":     {
      "png": _temp19
    },
    "ar":     {
      "png": _temp20
    },
    "as":     {
      "png": _temp21
    },
    "at":     {
      "png": _temp22
    },
    "au":     {
      "png": _temp23
    },
    "aw":     {
      "png": _temp24
    },
    "ax":     {
      "png": _temp25
    },
    "az":     {
      "png": _temp26
    },
    "ba":     {
      "png": _temp27
    },
    "bb":     {
      "png": _temp28
    },
    "bd":     {
      "png": _temp29
    },
    "be":     {
      "png": _temp30
    },
    "bf":     {
      "png": _temp31
    },
    "bg":     {
      "png": _temp32
    },
    "bh":     {
      "png": _temp33
    },
    "bi":     {
      "png": _temp34
    },
    "bj":     {
      "png": _temp35
    },
    "bl":     {
      "png": _temp36
    },
    "bm":     {
      "png": _temp37
    },
    "bn":     {
      "png": _temp38
    },
    "bo":     {
      "png": _temp39
    },
    "bq":     {
      "png": _temp40
    },
    "br":     {
      "png": _temp41
    },
    "bs":     {
      "png": _temp42
    },
    "bt":     {
      "png": _temp43
    },
    "bv":     {
      "png": _temp44
    },
    "bw":     {
      "png": _temp45
    },
    "by":     {
      "png": _temp46
    },
    "bz":     {
      "png": _temp47
    },
    "ca":     {
      "png": _temp48
    },
    "cc":     {
      "png": _temp49
    },
    "cd":     {
      "png": _temp50
    },
    "cf":     {
      "png": _temp51
    },
    "cg":     {
      "png": _temp52
    },
    "ch":     {
      "png": _temp53
    },
    "ci":     {
      "png": _temp54
    },
    "ck":     {
      "png": _temp55
    },
    "cl":     {
      "png": _temp56
    },
    "cm":     {
      "png": _temp57
    },
    "cn":     {
      "png": _temp58
    },
    "co":     {
      "png": _temp59
    },
    "cr":     {
      "png": _temp60
    },
    "cu":     {
      "png": _temp61
    },
    "cv":     {
      "png": _temp62
    },
    "cw":     {
      "png": _temp63
    },
    "cx":     {
      "png": _temp64
    },
    "cy":     {
      "png": _temp65
    },
    "cz":     {
      "png": _temp66
    },
    "de":     {
      "png": _temp67
    },
    "dj":     {
      "png": _temp68
    },
    "dk":     {
      "png": _temp69
    },
    "dm":     {
      "png": _temp70
    },
    "do":     {
      "png": _temp71
    },
    "dz":     {
      "png": _temp72
    },
    "ec":     {
      "png": _temp73
    },
    "ee":     {
      "png": _temp74
    },
    "eg":     {
      "png": _temp75
    },
    "eh":     {
      "png": _temp76
    },
    "er":     {
      "png": _temp77
    },
    "es":     {
      "png": _temp78
    },
    "et":     {
      "png": _temp79
    },
    "fi":     {
      "png": _temp80
    },
    "fj":     {
      "png": _temp81
    },
    "fk":     {
      "png": _temp82
    },
    "fm":     {
      "png": _temp83
    },
    "fo":     {
      "png": _temp84
    },
    "fr":     {
      "png": _temp85
    },
    "ga":     {
      "png": _temp86
    },
    "gb-eng":     {
      "png": _temp87
    },
    "gb-nir":     {
      "png": _temp88
    },
    "gb-sct":     {
      "png": _temp89
    },
    "gb-wls":     {
      "png": _temp90
    },
    "gb":     {
      "png": _temp91
    },
    "gd":     {
      "png": _temp92
    },
    "ge":     {
      "png": _temp93
    },
    "gf":     {
      "png": _temp94
    },
    "gg":     {
      "png": _temp95
    },
    "gh":     {
      "png": _temp96
    },
    "gi":     {
      "png": _temp97
    },
    "gl":     {
      "png": _temp98
    },
    "gm":     {
      "png": _temp99
    },
    "gn":     {
      "png": _temp100
    },
    "gp":     {
      "png": _temp101
    },
    "gq":     {
      "png": _temp102
    },
    "gr":     {
      "png": _temp103
    },
    "gs":     {
      "png": _temp104
    },
    "gt":     {
      "png": _temp105
    },
    "gu":     {
      "png": _temp106
    },
    "gw":     {
      "png": _temp107
    },
    "gy":     {
      "png": _temp108
    },
    "hk":     {
      "png": _temp109
    },
    "hm":     {
      "png": _temp110
    },
    "hn":     {
      "png": _temp111
    },
    "hr":     {
      "png": _temp112
    },
    "ht":     {
      "png": _temp113
    },
    "hu":     {
      "png": _temp114
    },
    "id":     {
      "png": _temp115
    },
    "ie":     {
      "png": _temp116
    },
    "il":     {
      "png": _temp117
    },
    "im":     {
      "png": _temp118
    },
    "in":     {
      "png": _temp119
    },
    "io":     {
      "png": _temp120
    },
    "iq":     {
      "png": _temp121
    },
    "ir":     {
      "png": _temp122
    },
    "is":     {
      "png": _temp123
    },
    "it":     {
      "png": _temp124
    },
    "je":     {
      "png": _temp125
    },
    "jm":     {
      "png": _temp126
    },
    "jo":     {
      "png": _temp127
    },
    "jp":     {
      "png": _temp128
    },
    "ke":     {
      "png": _temp129
    },
    "kg":     {
      "png": _temp130
    },
    "kh":     {
      "png": _temp131
    },
    "ki":     {
      "png": _temp132
    },
    "km":     {
      "png": _temp133
    },
    "kn":     {
      "png": _temp134
    },
    "kp":     {
      "png": _temp135
    },
    "kr":     {
      "png": _temp136
    },
    "kw":     {
      "png": _temp137
    },
    "ky":     {
      "png": _temp138
    },
    "kz":     {
      "png": _temp139
    },
    "la":     {
      "png": _temp140
    },
    "lb":     {
      "png": _temp141
    },
    "lc":     {
      "png": _temp142
    },
    "li":     {
      "png": _temp143
    },
    "lk":     {
      "png": _temp144
    },
    "lr":     {
      "png": _temp145
    },
    "ls":     {
      "png": _temp146
    },
    "lt":     {
      "png": _temp147
    },
    "lu":     {
      "png": _temp148
    },
    "lv":     {
      "png": _temp149
    },
    "ly":     {
      "png": _temp150
    },
    "ma":     {
      "png": _temp151
    },
    "mc":     {
      "png": _temp152
    },
    "md":     {
      "png": _temp153
    },
    "me":     {
      "png": _temp154
    },
    "mf":     {
      "png": _temp155
    },
    "mg":     {
      "png": _temp156
    },
    "mh":     {
      "png": _temp157
    },
    "mk":     {
      "png": _temp158
    },
    "ml":     {
      "png": _temp159
    },
    "mm":     {
      "png": _temp160
    },
    "mn":     {
      "png": _temp161
    },
    "mo":     {
      "png": _temp162
    },
    "mp":     {
      "png": _temp163
    },
    "mq":     {
      "png": _temp164
    },
    "mr":     {
      "png": _temp165
    },
    "ms":     {
      "png": _temp166
    },
    "mt":     {
      "png": _temp167
    },
    "mu":     {
      "png": _temp168
    },
    "mv":     {
      "png": _temp169
    },
    "mw":     {
      "png": _temp170
    },
    "mx":     {
      "png": _temp171
    },
    "my":     {
      "png": _temp172
    },
    "mz":     {
      "png": _temp173
    },
    "na":     {
      "png": _temp174
    },
    "nc":     {
      "png": _temp175
    },
    "ne":     {
      "png": _temp176
    },
    "nf":     {
      "png": _temp177
    },
    "ng":     {
      "png": _temp178
    },
    "ni":     {
      "png": _temp179
    },
    "nl":     {
      "png": _temp180
    },
    "no":     {
      "png": _temp181
    },
    "np":     {
      "png": _temp182
    },
    "nr":     {
      "png": _temp183
    },
    "nu":     {
      "png": _temp184
    },
    "nz":     {
      "png": _temp185
    },
    "om":     {
      "png": _temp186
    },
    "pa":     {
      "png": _temp187
    },
    "pe":     {
      "png": _temp188
    },
    "pf":     {
      "png": _temp189
    },
    "pg":     {
      "png": _temp190
    },
    "ph":     {
      "png": _temp191
    },
    "pk":     {
      "png": _temp192
    },
    "pl":     {
      "png": _temp193
    },
    "pm":     {
      "png": _temp194
    },
    "pn":     {
      "png": _temp195
    },
    "pr":     {
      "png": _temp196
    },
    "ps":     {
      "png": _temp197
    },
    "pt":     {
      "png": _temp198
    },
    "pw":     {
      "png": _temp199
    },
    "py":     {
      "png": _temp200
    },
    "qa":     {
      "png": _temp201
    },
    "re":     {
      "png": _temp202
    },
    "ro":     {
      "png": _temp203
    },
    "rs":     {
      "png": _temp204
    },
    "ru":     {
      "png": _temp205
    },
    "rw":     {
      "png": _temp206
    },
    "sa":     {
      "png": _temp207
    },
    "sb":     {
      "png": _temp208
    },
    "sc":     {
      "png": _temp209
    },
    "sd":     {
      "png": _temp210
    },
    "se":     {
      "png": _temp211
    },
    "sg":     {
      "png": _temp212
    },
    "sh":     {
      "png": _temp213
    },
    "si":     {
      "png": _temp214
    },
    "sj":     {
      "png": _temp215
    },
    "sk":     {
      "png": _temp216
    },
    "sl":     {
      "png": _temp217
    },
    "sm":     {
      "png": _temp218
    },
    "sn":     {
      "png": _temp219
    },
    "so":     {
      "png": _temp220
    },
    "sr":     {
      "png": _temp221
    },
    "ss":     {
      "png": _temp222
    },
    "st":     {
      "png": _temp223
    },
    "sv":     {
      "png": _temp224
    },
    "sx":     {
      "png": _temp225
    },
    "sy":     {
      "png": _temp226
    },
    "sz":     {
      "png": _temp227
    },
    "tc":     {
      "png": _temp228
    },
    "td":     {
      "png": _temp229
    },
    "tf":     {
      "png": _temp230
    },
    "tg":     {
      "png": _temp231
    },
    "th":     {
      "png": _temp232
    },
    "tj":     {
      "png": _temp233
    },
    "tk":     {
      "png": _temp234
    },
    "tl":     {
      "png": _temp235
    },
    "tm":     {
      "png": _temp236
    },
    "tn":     {
      "png": _temp237
    },
    "to":     {
      "png": _temp238
    },
    "tr":     {
      "png": _temp239
    },
    "tt":     {
      "png": _temp240
    },
    "tv":     {
      "png": _temp241
    },
    "tw":     {
      "png": _temp242
    },
    "tz":     {
      "png": _temp243
    },
    "ua":     {
      "png": _temp244
    },
    "ug":     {
      "png": _temp245
    },
    "um":     {
      "png": _temp246
    },
    "us":     {
      "png": _temp247
    },
    "uy":     {
      "png": _temp248
    },
    "uz":     {
      "png": _temp249
    },
    "va":     {
      "png": _temp250
    },
    "vc":     {
      "png": _temp251
    },
    "ve":     {
      "png": _temp252
    },
    "vg":     {
      "png": _temp253
    },
    "vi":     {
      "png": _temp254
    },
    "vn":     {
      "png": _temp255
    },
    "vu":     {
      "png": _temp256
    },
    "wf":     {
      "png": _temp257
    },
    "ws":     {
      "png": _temp258
    },
    "xk":     {
      "png": _temp259
    },
    "ye":     {
      "png": _temp260
    },
    "yt":     {
      "png": _temp261
    },
    "za":     {
      "png": _temp262
    },
    "zm":     {
      "png": _temp263
    },
    "zw":     {
      "png": _temp264
    }
  }
}